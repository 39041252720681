import { css } from "styled-components"

export const standardPageHeader = css`
  
  height: 540px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position-y: -300px;

  @media (max-width: 1260px) {
    background-position-y: initial;
  }

  /* @temp @TODO - extract this somewhere (it's for fullwidth) */
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  
  .content {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: border-box;
    padding-top: 100px;
    width: 100%;
    text-align: center;

    .heading {
      font-size: 60px;
      line-height: 70px;

      @media (max-width: 768px) {
        font-size: 42px;
        line-height: 50px;
      }

    }

    .text {
      font-size: 24px;
      line-height: 34px;
    }
    
  }

  .background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(72, 228, 183, 0.75);
  }
  
`

