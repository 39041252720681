import styled, { FlattenSimpleInterpolation } from "styled-components"
import { standardPageHeader } from "./standardPageHeader.css";

interface StandardPageHeaderStyledProps {
  backgroundImage?: string,
  extendStyles?: FlattenSimpleInterpolation
}

export const StandardPageHeaderStyled = styled.div<StandardPageHeaderStyledProps>`

  ${standardPageHeader}

  ${props => props.backgroundImage
    ? `background-image: url(${props.backgroundImage});`
    : `background-color: #F9F9F9;`
  }

  ${props => props.extendStyles || ''}

`