import React from "react"
import LogoWithNavigation from "../../_/LogoWithNav/LogoWithNav.component"
import { StandardPageHeaderStyled } from "./css/StandardPageHeaderStyled.css"
import CenterContainer from "../../../../../layouts/containers/CenterContainer/CenterContainer.component";
import { FlattenSimpleInterpolation } from "styled-components";

interface StandardPageHeaderProps {
  data?: {
    heading?: string,
    subText?: string,
    backgroundImage?: string
  },
  extendStyles?: FlattenSimpleInterpolation
}

const StandardPageHeader: React.FC<StandardPageHeaderProps> = ({data, extendStyles}) => (

  <StandardPageHeaderStyled 
    extendStyles={extendStyles ?? undefined}
    backgroundImage={data?.backgroundImage || undefined}>

    <div className="background-overlay"></div>

    <CenterContainer>

      <LogoWithNavigation />

      <div className="content">

        <h1 className="heading">{data && data.heading || "Heading placeholder"}</h1>

        {(data && data.subText && (
          <p className="text">{data.subText}</p>
        ))}

      </div>

    </CenterContainer>

  </StandardPageHeaderStyled>

)

export default StandardPageHeader;